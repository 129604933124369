var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("Unit Of Measurement")))]
            )
          ]),
          [
            _c(
              "avue-crud",
              {
                ref: "crud",
                attrs: {
                  option: _vm.tableOption,
                  data: _vm.tableData,
                  page: _vm.page,
                  defaults: _vm.defaults
                },
                on: {
                  "update:defaults": function($event) {
                    _vm.defaults = $event
                  },
                  "tree-load": _vm.treeLoad,
                  "current-change": _vm.currentChange,
                  "size-change": _vm.sizeChange,
                  "selection-change": _vm.selectionChange,
                  "refresh-change": _vm.refreshChange,
                  "row-save": _vm.handleSave,
                  "row-update": _vm.handleUpdate,
                  "row-del": _vm.handleDel
                },
                scopedSlots: _vm._u([
                  {
                    key: "groupForm",
                    fn: function(scope) {
                      return [
                        _c("el-autocomplete", {
                          staticClass: "inline-input",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "fetch-suggestions": _vm.querySearch,
                            placeholder:
                              _vm.$t("Please enter") + _vm.$t("UOM GROUPING"),
                            disabled: _vm.obj.deriveType > 1
                          },
                          model: {
                            value: _vm.obj.group,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "group", $$v)
                            },
                            expression: "obj.group"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "groupError",
                    fn: function(ref) {
                      var error = ref.error
                      return [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(error))
                        ])
                      ]
                    }
                  },
                  {
                    key: "menu",
                    fn: function(scope) {
                      return [
                        scope.row.deriveType > 1
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-s-tools",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("CUSTOM")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowEdit(
                                    scope.row,
                                    scope.row.index
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        scope.row.dataType == 3
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-remove",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("HIDDEN SYSTEM ITEM")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.syshidden(scope.row)
                                }
                              }
                            })
                          : _vm._e(),
                        scope.row.deriveType == 4 || scope.row.deriveType == 5
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-refresh-left",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("RESTORE SYSTEM ITEM")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sysRestore(scope.row)
                                }
                              }
                            })
                          : _vm._e(),
                        scope.row.dataType == 0 &&
                        scope.row.deriveType != 4 && scope.row.deriveType != 5
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-edit-outline",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("Edit")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowEdit(
                                    scope.row,
                                    scope.row.index
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        scope.row.dataType == 0 &&
                        scope.row.deriveType != 4 && scope.row.deriveType != 5
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-delete-solid",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("Delete")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDel(scope.row)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.obj,
                  callback: function($$v) {
                    _vm.obj = $$v
                  },
                  expression: "obj"
                }
              },
              [
                _c(
                  "template",
                  {
                    staticStyle: { width: "calc(100% - 80px)" },
                    slot: "menuLeft"
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", {
                          staticClass: "fromOutDiv",
                          attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                        }),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-top": "10px",
                              "text-align": "right",
                              "align-self": "flex-end"
                            },
                            attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "iconfont tianjia",
                                  size: "mini",
                                  title: _vm.$t("New"),
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.crud.rowAdd()
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("New")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  title: _vm.$t("Refresh"),
                                  icon: "iconfont shuaxin",
                                  size: "mini",
                                  plain: ""
                                },
                                on: { click: _vm.refreshChange }
                              },
                              [_vm._v("  " + _vm._s(_vm.$t("Refresh")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }